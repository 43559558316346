/* 
=================================================================================================================
=================================================================================================================
=== APP CONFIG ==================================================================================================
=================================================================================================================
=================================================================================================================

Please populate the below to make changes to the app.
Please do not remove any fields. 
*/


const appConfig = {
  /* 
  =================================================================================================================
  === GENERAL CONFIGS =============================================================================================
  =================================================================================================================
  */
  general: {
    app:'nhra-app', //new
    sdkService: 'fanFireSDK', // fanFireSDK | demo 
    logo: require('./assets/logo1white.png'), // Logo on the Nav bar
    contactDetails: {
      telephone: '021 833 9652',
      email: 'info@fanfire.ai',
      email_subject: 'Fanfire Inquiry', //new
    },
    socialMedia: {
      // add more possibilities for users
      instagram: 'https://www.instagram.com',
      twitter: 'https://www.twitter.com',
      facebook: 'https://www.facebook.com',
      youtube: 'https://www.youtube.com',
      custom: [ // TODO: support SVG
        { //Fanfire
          logo: require('./assets/FanFire_Logo_black.png'), //change to either black or white
          link: 'https://linktr.ee/fanfire'
        },
        // Add more objects here to add more options
      ]
    },
    // some general throughout configs
    disablePurchasing: false, // Disbles purchasing / listing / unlisting
    openseaLogo: require('./assets/OpenSeaBlue.png'), //Logo to use for Opensea (does not support svg yet)
    metaMaskLogo: require('./assets/MetaMask-Fox.png'), //Logo to use for Metamask
    listViewsErrorImage: require('./assets/placeholderImage.png'), // Image used on error pages when trying to list NFTs
    nftImageNotFoundImage: require('./assets/placeholderImage.png'), // Image to use on card item if image of NFT can not be found
    cardItemViewText: 'View Item', // Text displayed on card item to route into detail view
    serviceFee: 2.5, //Amount for the service
    creatorFee: 10, //Amount the creator gets
    creatorFeeText: 'Royaltee', //text displayed on list NFT for creator cut

    site_noun: 'item', // Noun shown to indicate what is being sold
    site_noun_plural: 'items', // Noun (plural) shown to indicate what is being sold

    enableQrRedemption: false,
    qrCodeImage: require('./assets/FanFire_Logo_black.png'),

    rightsText: 'Commercial Rights', //Text used for rights text on the checkout modal when buying an item
    rightsAgreeButtonText: "I understand the rights and agreements with purchasing this item.", // Text used for specifying the sentence to use where the user has to agree when buying an item

    //Filters for the Explore / for sale page
    // FILTERS
    // only works for numbers now
    // default_FF_sort: { //new
    //   ff_meta_field: 'Score', // field in FF metadata in DB
    //   sortBy: 'desc' //asc
    // },
    // default_FF_filter: true, //new
    avail_filters: [
      {
          name: 'Name',
          type: 'free-text',
      },
      // { //new
      //   name: 'Growth',
      //   type: 'select',
      //   fe_name: 'Category',
      //   ff_search: true,
      //   values: ['First Growth', 'Second Growth', 'Third Growth'],
      //   default: 'First Growth'
      // },
      // {
      //     name: 'Estate',
      //     type: 'select', 
      //     values: ['meerlust', 'vilafonte']
      // },
      // {
      //   name: 'Vintage',
      //   type: 'multi-select', 
      //   values: [2001, 2002, 2003, 2004, 2005]
      // },
      // {
      //   name: 'Volume (ml)',
      //   type: 'select', 
      //   values: [1000, 750]
      // },
    ],

    //Some General style overides in the app
    styleOverrides: {
      buttonPrimaryTextColor: {
        // color: 'black'
      }
    }
  },
  /* 
  =================================================================================================================
  === LADNING PAGE ================================================================================================
  =================================================================================================================
  */
  landingPage: {
    // Header on the Landing Page
    header: "Racehorse Investing Made Simple", 

    // Description on the Landing Page
    description: "Build a portfolio to fit your lifestyle, whether you want to own majority stake in a racehorse or just a small fraction. Regardless of which you select, the rush is the same, especially when it is felt from the winners' circle.",

    // This is the main image displayed on the Landing page
    landingImagePath: undefined, 

    landingVideoPath: require('./assets/video.mp4'), // either landingImagePath or landingVideoPath

    // Landing Page background Image: undefined or image -> require('./assets/<image-path.extension>')
    landingBackroundPath: undefined,

    // either byImage or byText - Specifies the CLient name through a logo image
    byImage: undefined, 

    // either byImage or byText - Specifies the CLient name through text
    byText: 'Powered by Fanfire.ai',

    // text before image on left
    byPreText: '',

    // Text that appears above the landing image
    // Keep as empty string to exlude
    textAboveImage: '',

    // Link Button that appears below the landing image
    linkBelowImage: {
      include: true, // Boolean to indicate whether to include or not
      link: 'https://www.fanfire.ai', // Where to link to
      text: 'Find out more about Fanfire', // Text for the link
      includeIcon: true, //include Icon next to text
    },

    // Primary Button on Landing Page:
    primary: {
      include: true, // Whether or not to include the button on the landing Page
      text: 'Racehorse Fractions', // Text defined within the button
      routeToPage: 'for-sale' // Where the button will route to (this must be one of the routeable pages)
    },

    // Secondary Button on Landing Page:
    secondary: {
      include: true, // Whether or not to include the button on the landing Page
      text: 'View All', // Text defined within the button
      routeToPage: 'explore' // Where the button will route to (this must be one of the routeable pages)
    },

    // Text style for landing page
    styleOverrides: {
      text: {
          // color: 'white'
      }
    }
  },
  /* 
  =================================================================================================================
  === BROWSE COLLECTION PAGE ======================================================================================
  =================================================================================================================
  */
  browseCollectionPage: {
    // Whether to include this page in the App
    include: true, 

    // Header on the explore page
    header: "Explore", 

    // undefined or image require('./assets/<image-path.extension>')
    headerImage: require('./assets/backg.png') 
  },
  /* 
  =================================================================================================================
  === NAV ITEMS ===================================================================================================
  =================================================================================================================
  */
  navItems: {
    forSaleNavbarText: "For Sale", //Text that will be displayed on the navbar that will route to the forsale page //new 
    backButtonNavIconColor: 'black', //new
    backButtonNavIconPaddingColor: 'white', //new
    navActiveColor: 'white', //new
    iconNavBarColor: 'white', //new
    // mobile drawer color of text nav items
    mobileNavItems: {
      styleOverride: {
        text: {
          color: 'black',
        },
        drawerColor: {
          // Color of the drawer that opens up to nav on the mobile view
           //background: '#fff6e8',
        },
      },
    },
  },
  /* 
  =================================================================================================================
  === PROFILE PAGE ================================================================================================
  =================================================================================================================
  */
  profilePage: {
    // Color of navbar line on active tab (Transactions, my items / liked items)
    navActiveColor: 'blue',
    dashboard: {
      styleOverride: {
        text: {
          // color: 'white'
        }
      }
    }
  },
  /* 
  =================================================================================================================
  === NFT DETAIL PAGE =============================================================================================
  =================================================================================================================
  */
  nftDetail:{
    // This option adds a custom description to every NFT detail page - Leave as '' to remove
    generalDescription: 'This NFT represents 0.1%, or one of 1000 shares, in this racehorse. Purchase more NFTs to increase your investment and potential returns.',

    // Custom accordian to add to every Detail Page
    customAccordian:{
      include: true, // Whether or not to include this accordian in detail view
      header: 'About this fraction', // Header text of the Custom accordian
      items: [
        'This racehorse NFT is one of 1000 fractions.', // item shown in accoridan dropdown sperated by a divider
        'Winnings are split up according to the percentage owned.' // item shown in accoridan dropdown sperated by a divider
      ],
      // link: { //new
      //   include: true,
      //   linkTo: '/FAQ',
      //   text: 'View More'
      // }
    },
    
  },
  /* 
  =================================================================================================================
  === FOR SALE PAGE ===============================================================================================
  =================================================================================================================
  */
  forSalePage: {
    // Whether to include this page in the App
    include: true, 

    // Header on the for sale page
    header: "Racehorses", 

    // undefined or image require('./assets/<image-path.extension>')
    headerImage: require('./assets/backg.png') 
  },
  /* 
  =================================================================================================================
  === NFT VAULT TABLE LIST PAGE ===================================================================================
  =================================================================================================================
  */
  nftVaultTable: {
    // Table to display all vault tokens,
    table: {
      /*
      Table is already populated with: Details, Name, Image, Description and a link to Opensea.
      The below options will have to exist within the attributes (properties) of the token metadata. 
      All options here will be displayed as a column within the table.
      */
      attributesFromMeta: ['Vintage', 'Seal Codes', 'Volume (ml)'],
    }
  },
  /* 
  =================================================================================================================
  === FOOTERS =====================================================================================================
  =================================================================================================================
  */
  footers: {
    // Text on mobile permanent footer,
    mobileText: 'Racehorse Investing',

    stayInformedText: 'Sign up for Fanfire newsletters and receive information on new items and other promotions.',

    footerLogo: require('./assets/logo1.png'),

    footerTextColor: {
      styleOverrides: {
        text: {
             color: 'black'
        },
        socialMediaFooterTextColor: {
          text: {
            // color: 'black'
          },
        }
      }
    }
  },
  /* 
  =================================================================================================================
  === ATTENDANCE TOKENS PAGE ======================================================================================
  =================================================================================================================
  */
  attendanceTokens: {
    // either byImage or byText - Specifies the CLient name through text
    byImage: undefined, 

    // either byImage or byText - Specifies the CLient name through text
    byText: 'Placeholder Client Name', 

    // Background Image of attandance token page
    background: undefined, 

    // Heading of the Attendance Token page
    heading: "Attendance Token Heading", 

    // Sub-heading of the Attendance Token page
    sub_heading: "Attendance Token sub heading", 
  },
}
export default appConfig

/* 
=================================================================================================================
=== FAQ CONFIG ==================================================================================================
=================================================================================================================

Enter All FAQ options in here. This will populate the FAQ Page.
*/
export const faqs = [
  {
    heading: 'Heading 1',
    questions: [
      {
        question: 'Question 1',
        answer: 'Answer 1'
      },
      {
        question: 'Question 2',
        answer: 'Answer 2'
      },
    ]
  },
  {
    heading: 'About Fanfire',
    questions: [
      {
        question: 'What does Fanfire do?',
        answer: 'Fanfire provides solutions for artists, athletes and businesses who recognise the opportunities presented by the third-generation web (“Web3”), such as cryptographic tokens, web wallets, and loyalty economies. The team has worked with a diverse range of partners such as the Cell C Sharks rugby franchise and Care for Wild Rhino Sanctuary to create new Web3 engagement strategies, and is also involved in digitizing the ownership and trade of collectible wines.'
      },
      {
        question: 'Who is Fanfire?',
        answer: `Fanfire is part of the Alphawave Group, a leading technology investment group consisting of businesses with products and services that are hard to replicate. The group is headquartered in Stellenbosch and applies South Africa's signature high-innovation-low-cost creativity to concepts with world-wide application. 
        The company is led by the former Research Chair in IoT at Stellenbosch University (who co-founded Custos, an InfoSec blockchain company, in 2013). It is chaired by an industry veteran who has led two JSE-listed companies, and has decades of experience in technology, entertainment and consumer businesses.`
      },
      {
        question: 'What are NFTs?',
        answer: `A non-fungible token or NFT is merely an ownership certificate.
        In much the same way that a title deed represents the ownership of a house, an NFT is a cryptographically-secured digital certificate that proves ownership of something.
        It can represent ownership of anything; such as commercial rights (of a movie or artwork), it could represent ownership of a certain privilege (such as VIP access to a sport stadium or event), or even represent ownership of a physical collectable (such as a wine vintage) — the possibilities are endless. NFTs are validated and secured on blockchains.`
      },
      {
        question: 'What is a blockchain?',
        answer: `In short, a blockchain is a method of recording information such that it is nearly impossible to alter, hack or cheat the system. 
        A blockchain is underpinned by a decentralised (meaning not one institution has custody over it) digital ledger that tracks transactions using secure and trustless protocols. The most famous blockchains include the Bitcoin- and Ethereum-blockchains. 
        Blockchain and cryptocurrencies are not the same. Bitcoin and USDC are two examples of a cryptocurrency, like rands or dollars, that run on a blockchain. These cryptocurrencies are merely a few lines of code (known colloquially as tokens) in a smart contract that gets executed.`
      },
      {
        question: 'What is USDC?',
        answer: 'USD Coin or USDC is a cryptocurrency (like Bitcoin of Ethereum) which is pegged to the United States dollar, making it extremely stable. USDC was launched in 2018 by a consortium of companies called Centre, and is is fully collateralised while being US dollar-backed.'
      },
      {
        question: `What is a 'Web 3 wallet'?`,
        answer: 'A Web3 wallet is essentially a digital wallet which contains all your digital assets, such as NFTs and other coins (or cryptocurrency).'
      },
      {
        question: 'How can I sell my NFT?',
        answer: `On the Fanfire platform you will be able to 'list' your NFTs for sale by clicking the 'List Item' button on your NFT profile page.`
      },
      {
        question: 'How do I redeem my funds?',
        answer: 'Fanfire uses VALR.com, a trusted digital asset trading platform, for users to convert their cryptocurrency into rands. VALR enables the seamless buying, selling, storing and transfer of any cryptocurrency safely and securely in ZAR.'
      },
      {
        question: 'Why does my transaction take so long?',
        answer: `Fanfire's platform is extremely secure and depends on a number of protocols to facilitate a transaction. Some transactions might take longer than others to complete as the blockchain needs to be updated.`
      }
    ]
  }
]

